import React, { useEffect, useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import {
    emailValidator,
    requiredValidator,
    passwordValidator,
    passwordMatchValidator,
    partsCodeValidator,
    address1Validator,
    cityValidator,
    zipValidator,
    phoneValidator,
} from '../validators';
import { states } from '../resources/states';
import '../styles/_forms.scss';

import { AppContext } from './../AppContext'
import { useStores } from 'src/stores';
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import logo from "../assets/images/Ford_D4URS_Blue_Screen_RGB_v1.png";

// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { DropDownList } from 'src/components/form/DropDownList';
import { MaskedTextBox } from 'src/components/form/MaskedTextBox';

const SignUpScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const history = useHistory();
    const { language } = localizationService;
    console.log('localizationService!!!!', localizationService);

    const [loginError, setLoginError] = useState<string>();
    const { login } = useStores();
    const { d4ur } = useServices();

    useEffect(() => {
        if (login.dealer) {
            console.log('[SignUpScreen]', 'I think we are already logged in');
            d4ur.getAccount().then(() => {
                history.push('/events');
            });
        }
        // eslint-disable-next-line
    }, [])

    const onSubmit = React.useCallback(
        async (dataItem) => {
            const dealerSignup: Dealer = dataItem;

            login.setLoginEmail(dealerSignup.contact_email);
            const call: any = await d4ur.doSignup(dealerSignup, dataItem.password);
            if (call.result.errors) {
                console.log('[SignUpScreen]', 'Signup Error');
                setLoginError(call.result.errors.join('\n'));
            }
            if (call.result.dealer && call.result.token) {
                console.log('[SignUpScreen]', 'created & logged in');
                login.setDealerToken(call.result.token)
                login.setDealer(call.result.dealer as Dealer);
                history.push('/events');
            }
        },
        [login, history, d4ur]
    );

    return (
        <div id="Login" className="home-page main-content">
            <div className="signup-content">
                <div className={'logo_container'}>
                    <img src={logo} alt={'Ford Drive 4 UR School Logo'} className={'logo'} onClick={() => {
                                        history.push("/");
                                    }}/>
                </div>
                <div className="form-container" style={{ maxWidth: 700 }}>
                    <div className="card-component">
                        <h1 className="title">{localizationService.toLanguageString('custom.signUp', 'Sign Up')}</h1>
                        <p className="card-text">{localizationService.toLanguageString('custom.signUpText', 'Sign Up Text')}</p>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...formValues,
                                contact_email: login.loginEmail,
                            }}
                            render={(formRenderProps) => (
                                <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                    {loginError && <Error>{loginError}</Error>}
                                    <Field
                                        id={'dealer_name'}
                                        name={'dealer_name'}
                                        maxLength={25}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.dealer_name', 'dealer_name')}
                                        validator={requiredValidator}
                                        required={true}
                                        component={Input}
                                        hint={'Max Length: 25 characters'}
                                    />
                                    <Field
                                        id={'parts_code'}
                                        name={'parts_code'}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.sales_code', 'sales_code')}
                                        validator={partsCodeValidator}
                                        hint={'The Dealer Sales Code starts with an L-. Please enter the 5 digits that follow the L.'}
                                        required={true}
                                        mask="00000"
                                        component={MaskedTextBox}
                                    />
                                    <Field
                                        id={'address'}
                                        name={'address'}
                                        maxLength={45}
                                        // type={'input'}
                                        hint={'Please Do NOT Provide a PO Box'}
                                        label={localizationService.toLanguageString('custom.dealer_address', 'address')}
                                        validator={address1Validator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'address2'}
                                        name={'address2'}
                                        maxLength={45}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.address2', 'address2')}
                                        component={Input}
                                    />
                                    <Field
                                        id={'city'}
                                        name={'city'}
                                        maxLength={45}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.city', 'city')}
                                        validator={cityValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        component={DropDownList}
                                        id={'state'}
                                        name={'state'}
                                        label={localizationService.toLanguageString('custom.state', 'state')}
                                        data={states}
                                        validator={requiredValidator}
                                        required={true}
                                    />
                                    <Field
                                        id={'zip'}
                                        name={'zip'}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.zip', 'zip')}
                                        validator={zipValidator}
                                        required={true}
                                        mask="00000"
                                        component={MaskedTextBox}
                                    />
                                    <Field
                                        id={'phone'}
                                        name={'phone'}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.dealershipPhone', 'dealershipPhone')}
                                        validator={phoneValidator}
                                        required={true}
                                        mask="000-000-0000"
                                        placeholder={'e.g.: 000-000-0000'}
                                        component={MaskedTextBox}
                                    />
                                    <Field
                                        id={'primary_contact'}
                                        name={'primary_contact'}
                                        maxLength={45}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.primary_contact', 'primary_contact')}
                                        validator={requiredValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'contact_phone'}
                                        name={'contact_phone'}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.contact_phone', 'contact_phone')}
                                        validator={phoneValidator}
                                        required={true}
                                        mask="000-000-0000"
                                        placeholder={'e.g.: 000-000-0000'}
                                        component={MaskedTextBox}
                                    />
                                    <Field
                                        id={'contact_email'}
                                        name={'contact_email'}
                                        type={'email'}
                                        autoComplete="username"
                                        placeholder={'e.g.: peter@gmail.com'}
                                        label={localizationService.toLanguageString('custom.dealerContactEmail', 'Email')}
                                        validator={emailValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'password'}
                                        name={'password'}
                                        autoComplete="new-password"
                                        type={'password'}
                                        label={localizationService.toLanguageString('custom.password', 'Password')}
                                        validator={passwordValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'confirm_password'}
                                        name={'confirm_password'}
                                        type={'password'}
                                        autoComplete="new-password"
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.confirmPassword', 'Confirm Password')}
                                        validator={passwordMatchValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <div className='create_btn_container'>
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}
                                            className='standard_btn'
                                        >
                                            {localizationService.toLanguageString('custom.signUp', 'Sign Up')}
                                        </Button>
                                    </div>
                                </FormElement>
                            )}
                        />
                          <div className="login_container log_in">
                               <p className='login_text'>Already have an account?</p>
                               <button 
                                    className='login_btn' 
                                    onClick={() => {
                                        history.push("/login");
                                    }}>
                                    Log In
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0', }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
}

export default SignUpScreen;
