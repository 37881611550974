import {
  default as React,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

import { Input } from "./../components/form/Input";

import { AppContext } from "./../AppContext";
// @ts-ignore
import PrivacyBanner from "react-ford-ccpa-banner";

import {
  requiredValidator,
  emailValidator,
  phoneValidator,
  newPasswordValidator,
  passwordMatchValidator,
  address1Validator,
  cityValidator,
  zipValidator,
} from "./../validators";
import { states } from "../resources/states";

import { useServices } from "src/services";
import { useStores } from "src/stores";
import { Error } from "@progress/kendo-react-labels";
import { DropDownList } from "src/components/form/DropDownList";
import { MaskedTextBox } from "src/components/form/MaskedTextBox";
import { Loader } from "@progress/kendo-react-indicators";
import "../styles/_forms.scss";

const Profile = () => {
  const { ...formValues } = useContext(AppContext);
  const localizationService = useLocalization();
  const { language } = localizationService;
  const { d4ur } = useServices();
  const { login } = useStores();
  const history = useHistory();

  const [formError, setFormError] = useState<string>();
  const [thisDealer, setThisDealer] = useState<Dealer>(login.dealer!);
  const [processing, setProcessing] = React.useState<boolean>(true);

  useEffect(() => {
    d4ur.getDealer().then((ret) => {
      const { dealer } = (ret as D4URFetchResult).result;

      const dealerTest = dealer as Dealer;

      setThisDealer(dealerTest);
      setProcessing(false);
    });
  }, [d4ur]);

  const onSubmit = useCallback(
    async (dataItem) => {
      console.log("dataItem!!!!!!!!", dataItem);
      setProcessing(true);

      const dealerUpdate: Dealer = dataItem;

      const call: any = await d4ur.updateDealer(dealerUpdate);
      if (call.result.errors) {
        console.log("[ProfileScreen]", "Dealer Update Error");
        setFormError(call.result.errors.join("\n"));
        setProcessing(false);
      } else {
        console.log("[ProfileScreen]", "Dealer Updated");
        login.setDealer(call.result.dealer as Dealer);
        history.push("/events");
      }
    },
    [d4ur, history, login]
  );

  return (
    <div id="Profile" className="signup-page">
      <div className="signup-content">
        <div className="form-container" style={{ maxWidth: 700 }}>
          {/* <div className={'logo_container'}>
                    <img src={logo} alt={'Ford Drive 4 UR School Logo'} className={'logo'} onClick={() => {
                                        history.push("/events");
                                    }}/>
                </div> */}
          <h1 className="request-title" style={{ marginBottom: "1rem" }}>
            Edit Dealer Profile
          </h1>
          {processing ? (
            <div style={{ textAlign: "center" }}>
              <Loader type="infinite-spinner" size="large" />
            </div>
          ) : (
            <div className='form_wrapper'>
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  ...formValues,
                  ...thisDealer,
                }}
                render={(formRenderProps) => (
                  <FormElement horizontal={true}>
                    {formError && <Error>{formError}</Error>}
                    <Field
                      id={"dealer_name"}
                      name={"dealer_name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.dealer_name",
                        "dealer_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                      maxLength={50}
                    />
                    <Field
                      id={"address"}
                      name={"address"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address",
                        "address"
                      )}
                      validator={address1Validator}
                      component={Input}
                    />
                    <Field
                      id={"address2"}
                      name={"address2"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address2",
                        "address2"
                      )}
                      component={Input}
                    />
                    <Field
                      id={"city"}
                      name={"city"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.city",
                        "city"
                      )}
                      validator={cityValidator}
                      component={Input}
                    />
                    <Field
                      component={DropDownList}
                      id={"state"}
                      name={"state"}
                      label={localizationService.toLanguageString(
                        "custom.state",
                        "state"
                      )}
                      data={states}
                      validator={requiredValidator}
                      required={true}
                    />
                    <Field
                      id={"zip"}
                      name={"zip"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.zip",
                        "zip"
                      )}
                      validator={zipValidator}
                      required={true}
                      mask="00000"
                      component={MaskedTextBox}
                    />
                    <Field
                      id={"phone"}
                      name={"phone"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.dealershipPhone",
                        "dealershipPhone"
                      )}
                      validator={phoneValidator}
                      required={true}
                      mask="000-000-0000"
                      placeholder={"e.g.: 000-000-0000"}
                      component={MaskedTextBox}
                    />
                    <Field
                      id={"primary_contact"}
                      name={"primary_contact"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.primary_contact",
                        "primary_contact"
                      )}
                      validator={requiredValidator}
                      component={Input}
                      maxLength={50}
                    />
                    <Field
                      id={"contact_phone"}
                      name={"contact_phone"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_phone",
                        "contact_phone"
                      )}
                      validator={phoneValidator}
                      required={true}
                      mask="000-000-0000"
                      placeholder={"e.g.: 000-000-0000"}
                      component={MaskedTextBox}
                    />
                    <Field
                      id={"contact_email"}
                      name={"contact_email"}
                      type={"email"}
                      autoComplete="username"
                      placeholder={"e.g.: peter@gmail.com"}
                      label={localizationService.toLanguageString(
                        "custom.dealerContactEmail",
                        "Email"
                      )}
                      validator={emailValidator}
                      required={true}
                      component={Input}
                    />
                    <h3 className="title">To Change Your Password:</h3>
                    <Field
                      id={"password"}
                      name={"password"}
                      autoComplete="new-password"
                      type={"password"}
                      label={localizationService.toLanguageString(
                        "custom.password",
                        "Password"
                      )}
                      validator={newPasswordValidator}
                      component={Input}
                    />
                    <Field
                      id={"confirm_password"}
                      name={"confirm_password"}
                      type={"password"}
                      autoComplete="new-password"
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.confirmPassword",
                        "Confirm Password"
                      )}
                      validator={passwordMatchValidator}
                      component={Input}
                    />
                    <div className='create_btn_container'>
                      <Button
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                        className="standard_btn"
                      >
                        {localizationService.toLanguageString(
                          "custom.save",
                          "save"
                        )}
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            </div>
          )}
        </div>
      </div>
      <PrivacyBanner
        containerStyle={{ padding: "10px 0 0", width: "100%" }}
        privacyContainerStyle={{
          padding: "10px 0",
          width: "60%",
          marginLeft: "20%",
        }}
        showPrivacy={true}
        language={language}
        team={"ford"}
        imgStyle={{ paddingLeft: "30px" }}
        otherLinks={[
          { text: "CONTACT US", url: "/contact", target: '_blank' },
          { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
          { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
      ]}
      />
    </div>
  );
};

export default Profile;
