import * as React from "react";
import * as PropTypes from "prop-types";
import d4urLogo from "../assets/images/Ford_D4URS_Blue_Screen_RGB_v1.png";

// import { useLocalization } from "@progress/kendo-react-intl";

export const Header = (props) => {
  const { onButtonClick, history } = props;

  // const localizationService = useLocalization();

  return (
    <header className="header" style={{ backgroundColor: "#F0F0F0" }}>
      <div className="nav-container">
        <div className="title">
          <img src={d4urLogo} alt="Ford Drive 4 UR School Logo" style={{ width: 100, cursor: 'pointer'}} onClick={() => history.push('/events')}/>
        </div>
        <div className="menu-button">
          <span className={"k-icon k-i-menu"} onClick={onButtonClick} />
        </div>
        {/*<div className="settings"></div>*/}
        {/*<div className="settings">*/}
        {/*            <span>{localizationService.toLanguageString('custom.language')}</span>*/}
        {/*            <DropDownList*/}
        {/*                textField={'locale'}*/}
        {/*                dataItemKey={'localeId'}*/}
        {/*                data={locales}*/}
        {/*                value={currentLanguage}*/}
        {/*                onChange={onLanguageChange}*/}
        {/*            />*/}
        {/*        </div>*/}
        {/*        <Avatar type={'image'} shape={'circle'}>*/}
        {/*            {*/}
        {/*                hasImage ?*/}
        {/*                    <img ref={imgRef} src={'#'} alt={'User Avatar'} /> :*/}
        {/*                    <img src={userAvatar} alt="user-avatar"/>*/}
        {/*            }*/}
        {/*        </Avatar>*!/*/}
      </div>
    </header>
  );
};

Header.displayName = "Header";
Header.propTypes = {
  page: PropTypes.string,
  onButtonClick: PropTypes.func,
  history: PropTypes.object,
};
