import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../../assets/images/Ford_D4URS_Blue_Screen_RGB_v1.png";
import { useHistory } from "react-router-dom";
const ResourcesScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;
    let history = useHistory();

    return (
        <div id="Profile" className="guidelines-page">
            <div className='min-height'>
                <img src={logo} alt={'Ford Drive 4 UR School Logo 100 years'} className={'logo'} onClick={() => {
                    history.push("/events");
                }} />
                <div className="content">
                    <div className="card-component">
                        <h1 className="card-title">Program Resources</h1>
                        <p>Thank you for expressing interest in hosting a Drive 4 UR School event. Please click below for Program Resources to assist you with planning.</p>
                        {/* <a className='link a-list' href="/downloads/PLANNING_GUIDE.pptx" target="_blank">Event Planning Guide</a> */}
                        <a className='link a-list' href="/resources/faqs">Event FAQs</a>
                        {/* <a className='link a-list' href="/resources/info" target="_blank">Event Organization Information</a> */}
                        <a className='link a-list' href="/downloads/Drive4URSchool_EventPlanningGuide_v4.pdf" target="_blank">Event Planning Guide</a>
                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank' },
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
};

export default ResourcesScreen;
