import React from 'react';

import D4URService from './d4urDealerPortal';

type ServiceType = {
  [index: string]: IService;
  'd4ur': typeof D4URService;
};

export const services:ServiceType = {
  d4ur: D4URService,
};

const servicesContext = React.createContext(services);

export const withServicesProvider = (C: React.FC) => (props: object) => {
  return (
    <servicesContext.Provider value={services}>
      <C {...props} />
    </servicesContext.Provider>
  );
};

export const useServices = () => React.useContext(servicesContext);

export const initServices = async () => {
  for (const key in services) {
    if (Object.prototype.hasOwnProperty.call(services, key)) {
      const s = services[key];

      if (s.init) {
        await s.init();
      }
    }
  }
};