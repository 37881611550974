import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../assets/images/Ford_D4URS_Blue_Screen_RGB_v1.png";
import { useHistory } from "react-router-dom";
const TermsScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;
    let history = useHistory();

    return (
        <div id="Login" className="guidelines-page">
            <img src={logo} alt={'Ford Drive 4 UR School Logo 100 years'} className={'logo'} onClick={() => {
                history.push("/events");
                                    }} />
                {/* <div className="card-container" style={{ maxWidth: 700 }}> */}

                    {/* <div className="card-component"> */}
            <div className="content">
                        <h1 className="card-title">Program Terms and Conditions</h1>
                        <p>In submitting my Drive 4 UR School (D4URS) event request, I recognize that if approved to host a D4URS event, I am responsible for understanding and agreeing to the following:</p>
                        <ol>
                            <li>
                            I understand that in order to be eligible to host a 2024 Ford D4URS event, the Ford dealer must be a Ford Brand Exclusive Advertising Program-eligible dealer, and the benefitting organization must pass the vetting requirements.
                            </li>
                            <li>
                            I accept that I cannot host more than one (1) fully funded Ford D4URS event in 2024.
                            </li>
                            <li>
                            My event must be held on a single day and at one location only.
                            </li>
                            <li>
                            I will be sent a confirmation email after I submit my D4URS event enrollment request on the program site.
                            </li>
                            <li>
                            I will bring a minimum of one (1) hybrid vehicle and one (1) Ford Blue™ vehicle to my event.
                            </li>
                            <li>
                            If I am a Ford Model e™ EV-Certified Elite Dealer, I will also bring a minimum of one (1) Model e vehicle to my event in addition to the one (1) hybrid and one (1) Ford Blue vehicle   
                            </li>
                            <li>
                            I understand that for each test-drive, each waiver must be submitted digitally through my event's unique registration link. Paper waivers will not be available for the 2024 D4URS events.
                            </li>
                            <li>
                            My store and I are responsible for adhering to all local and state laws regarding test-drives as applicable to my event.
                            </li>
                            <li>
                            My event location will be within my Ford dealer PMA/DMA, and I will contact my Ford Specialist with any questions regarding my Ford dealer PMA/DMA.
                            </li>
                            <li>
                            My event cannot be hosted on the same day or in conjunction with another Ford or Ford Motor Company-sponsored event.
                            </li>
                            <li>
                            I understand that Ford will only distribute donation funds to the chosen and approved organization after an event has been completed and test-drives have been validated*. I further understand that Ford will not distribute donation funds if an event does not take place and is cancelled for any reason.
                            </li>
                            <li>
                            I understand that I may not work with more than two (2) schools per event.
                            </li>
                            <li>
                            If I am working with two (2) schools, I understand that my dealership is responsible for coordinating with the lead school receiving the check to ensure funds are distributed to both schools.    
                            </li>
                            <li>
                            If I want to cancel my event for any reason, I am responsible for contacting PHQ at <a href="mailto:drive4urschool@gtb.com">drive4urschool@gtb.com</a> to communicate my event cancellation.
                            </li>
                            <li>
                            Maximum donation for an event is $6,000. NO PURCHASE OR ACTION IS NECESSARY. Donation of $20 per valid test-drive, up to 300 test-drives, for a total donation of $6,000 per event. Limit one (1) donation per household.
                            </li>
                            <li>
                            I understand that if the benefitting organization does not cash their donation check within 60 days of issue, Ford reserves the right to donate the funds to another organization of their choice.
                            </li>
                            <li>
                            Test-drivers must be 18 years of age or older with a valid driver's license and valid automobile insurance.
                            </li>
                            <li>
                            I understand that Ford will provide various promotional materials free of charge. This could include customizable, digital promotional materials for use by the dealer and organization to promote registration.
                            </li>
                            <li>
                            I accept that select events, as determined by PHQ, may receive an influencer or content capturer to attend my event.
                            </li>
                        </ol>
                        <p>
                            Should you have questions, please contact D4URS PHQ <a href="mailto:drive4urschool@gtb.com">drive4urschool@gtb.com</a>.
                        </p>
                        <p>
                        * NO PURCHASE NECESSARY. Must be 18 years of age or older with a valid driver's license and valid automobile insurance. Donation of $20 per valid test-drive, up to 300 test-drives, for a total donation of $6,000 per event. Limit one (1) donation per household. If the benefitting school does not cash their donation check within 60 days of issue, Ford reserves the right to donate the funds to another organization of their choice.
                        </p>
                    {/* </div> */}
                {/* </div> */}
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
};

export default TermsScreen;
