import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../../assets/images/D4URS_FordOval_RGB_v2.png";
const InfoScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;

    return (
        <div id="Profile" className="guidelines-page content">
            <img src={logo} alt={'Ford Drive 4 UR School Logo 100 years'} className={'logo'} />
            <div className="card-container">
                <div className="card-component">
                    <h1 className="card-title">Program Resources</h1>
                    <h2>Organization Information</h2>
                    <p>Lincoln wants to ensure we are working with organizations that align with our brand values. It is strongly encouraged to partner with an organization that enhances people’s well-being by improving their physical, mental, and environmental state via fundraising, volunteerism, and activity programs.</p>
                    <p>All organizations will need to be submitted for approval during enrollment. If there are any questions or concerns about an organization’s eligibility for the program, please contact Program HQ (<a href="mailto:drive4urschool@gtb.com">drive4urschool@gtb.com</a>). Approval will be determined by Program HQ and Lincoln.</p>
                    <h2>Organization Requirements:</h2>
                    <ul>
                        <li>The organization must be a 501(c)(3) in good standing with charity verification sources such as <a className="link" href="https://www.guidestar.org/" target="_blank" rel="noreferrer">Guidestar</a> or <a className="link" href="https://www.charitynavigator.org/" target="_blank" rel="noreferrer">Charity Navigator</a></li>
                        <li>Organizations and initiatives should operate with an 85/15 cause donation to overhead cost ratio</li>
                        <li>The organization must be OFAC (Office of Foreign Assets Controls)-compliant according to sanctions and regulations prohibiting U.S. citizens, businesses and financial institutions from engaging in business or financial transactions with persons or entities on OFAC’s Specially Designated Nationals (SDN) List</li>
                    </ul>
                    <h2>Not Approved Organizations</h2>
                    <p>Organizations or initiatives that will not be approved for Driven to Give events include:</p>
                    <ul>
                        <li>Religious programs or sectarian programs for religious purposes, such as religious education, religious facility improvements and mission trips. Programs operated through a church (e.g., soup kitchens, homeless assistance programs, etc.) will be vetted on a case-by-case basis</li>
                        <li>Chambers of commerce or local organizations with a main goal of improving retailer and/or small-business profits. Philanthropic outreach of a chamber may be considered, such as scholarship funds or community improvement</li>
                        <li>Political organizations or efforts to influence legislation, or the outcome of any elections or any specific election of candidates to public office, or to carry on any voter registration drive</li>
                        <li>Labor groups</li>
                        <li>Loans and development for small businesses</li>
                        <li>Organizations that promote discrimination in their charters</li>
                        <li>Organizations that unlawfully discriminate in their provision of goods and services
                            based on race, color, religion, gender identity or expression, ethnicity, sexual
                            orientation, national origin, physical challenge, age or status</li>
                        <li>Profit-making enterprises</li>
                        <li>Advocacy-directed groups and programs</li>
                        <li>Golf outings or non-organized teams/leagues that are not recognized by the city to
                            the municipality (e.g., beer leagues)</li>
                        <li>Direct payments to colleges are not eligible; however, club sports, scholarship
                            funds and community outreach by college-based organizations are permitted</li>
                        <li>Direct payments to fraternal organizations, including fraternities and sororities on
                            college campuses are not permitted. However, an event can be hosted by a fraternity or sorority to benefit their national organizations. In this instance, the check would need to be written directly to the charitable organization</li>
                        <li>Organizations that provide services or outreach that may be considered sensitive or controversial</li>
                        <li>Individual sponsorships related to fundraising activities</li>
                        <li>Designated funding purpose toward building, updates and/or renovations</li>
                    </ul>

                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
};

export default InfoScreen;
