import moment from 'moment'
import { stores } from 'src/stores';
import { API_URL } from '../utils/api_url';

// const baseURL = 'https://staging.api.fordshowtracker.com/';
const baseURL = API_URL;

const doFetch = async (url: RequestInfo, options: RequestInit, skipResults: boolean = false):Promise<D4URFetchResult> => {
  // console.log('[d4ur]', 'doFetch', url);

  // add standard header stuff
  options.headers = {
    'Content-Type': 'application/json',
    'Authorization': stores.login.dealerToken,
    'latitude-app-version': `web:${stores.ui.appVersion}`,
    ...options.headers,
  };

  const response = await window.fetch(url, options);
  let result:object;
  try {
    result = await response.json();
  } catch (e:unknown) {
    console.error(`[doFetch]`, e);
    result = {};
  }

  // console.log(`response ${url}`, {
  //   status: response.status,
  //   statusText: response.statusText,
  //   data: result,
  //   headers: response.headers,
  // });
  return {response, result};
}

const doMultipart = async (url: RequestInfo, options: RequestInit, skipResults: boolean = false):Promise<D4URFetchResult> => {
  // console.log('[d4ur]', 'doMultipart', url);

  // add standard header stuff
  options.headers = {
    'Authorization': stores.login.dealerToken,
    'latitude-app-version': `web:${stores.ui.appVersion}`,
    ...options.headers,
  };

  const response = await window.fetch(url, options);
  let result:object;
  try {
    result = await response.json();
  } catch (e:unknown) {
    console.error(`[doMultipart]`, e);
    result = {};
  }

  // console.log(`response ${url}`, {
  //   status: response.status,
  //   statusText: response.statusText,
  //   data: result,
  //   headers: response.headers,
  // });
  return {response, result};
}

class D4URService implements IService {
  init = async () => {
    console.log(`[FordD4URAPI] init`);
  }

  doLogin = async (email: string, password: string):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] doLogin`);

    try {
      stores.ui.setIsLoggingIn(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({email, password}),
      };

      const ret = await doFetch(`${baseURL}v1/auth/signin`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsLoggingIn(false);
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('doLogin', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] doLogin`, e);
      return e;
    }
  }

  doSignup = async (dealerSignup:Dealer, password:string):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] doSignup`);

    try {
      stores.ui.setIsLoggingIn(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          ...dealerSignup,
          password,
        }),
      };

      const ret = await doFetch(`${baseURL}v1/auth/signup`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsLoggingIn(false);
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('doSignup', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] doSignup`, e);
      return e;
    }
  }

  checkWaitlist = async ():Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] checkWaitlist`);
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };
      
      const ret = await doFetch(`${baseURL}v1/auth/check-waitlist`, requestOptions); 
      return ret;
    } catch (e) {
      // handle error
      console.error('checkWaitlist', e);
      console.error(`[FordD4URAPI] checkWaitlist`, e);
      return e;
    }
  }

  joinWaitlist = async (dealerID:number, dealerEmail: string):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] join-waitlist`);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({dealerID, dealerEmail}),
      };

      const ret = await doFetch(`${baseURL}v1/auth/join-waitlist`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('join-waitlist', e);
      console.error(`[FordD4URAPI] join-waitlist`, e);
      return e;
    }
  }

  doForgotPassword = async (contact_email:string):Promise<D4URFetchResult | unknown | undefined> =>  {
    console.log(`[FordD4URAPI] doForgotPassword`);

    try {
        stores.ui.setIsResettingPassword(true);
        const requestOptions: RequestInit = {
            method: 'POST',
            body: JSON.stringify({
                contact_email,
            }),
        };

        const ret = await doFetch(`${baseURL}v1/auth/verify_email`, requestOptions);
        setTimeout(() => {
            stores.ui.setIsResettingPassword(false);
        }, 1000);
        return ret;
    } catch (e) {
        // handle error
        console.error('doForgotPassword', e);
        stores.ui.setIsResettingPassword(false);
        console.error(`[FordD4URAPI] doForgotPassword error`, e);
        return e;
    }
  }

  doResetPassword = async (password:string, token: string):Promise<D4URFetchResult | unknown | undefined> =>  {
    console.log(`[FordD4URAPI] doResetPassword`);

    try {
        stores.ui.setIsResettingPassword(true);
        const requestOptions: RequestInit = {
            method: 'POST',
            body: JSON.stringify({
                password,
            }),
            headers: {token}
        };

        const ret = await doFetch(`${baseURL}v1/auth/change_password`, requestOptions);
        setTimeout(() => {
            stores.ui.setIsResettingPassword(false);
        }, 1000);
        return ret;
    } catch (e) {
        // handle error
        console.error('doForgotPassword', e);
        stores.ui.setIsResettingPassword(false);
        console.error(`[FordD4URAPI] doResetPassword error`, e);
        return e;
    }
  }

  doLogout = async ():Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] doLogout`);

    try {
      stores.ui.setIsLoggingOut(true);
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      await doFetch(`${baseURL}logout`, requestOptions);

      stores.login.doLogout();
      stores.ui.setIsLoggingOut(false);
      return;
    } catch (e) {
      // handle error
      console.error('doLogout', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] doLogout error`, e);
      return e;
    }
  }

  getEventToken = async (event_id:string):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getEventToken`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({event_id}),
      };

      const ret = await doFetch(`${baseURL}v1/auth/create_login_token`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getEventToken', e);
      console.error(`[FordD4URAPI] getEventToken`, e);
      return e;
    }
  }

  getAccount = async ():Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getAccount`);

    try {
      stores.ui.setIsLoggingIn(true);

      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret:D4URFetchResult = await doFetch(`${baseURL}v1/auth/account`, requestOptions);

      if (ret.response.status === 403) {
        console.error('getAccount', 'User not logged in');
        stores.login.doLogout();
        return;
      }

      stores.login.setDealer(ret.result.dealer as Dealer);

      return ret;
    } catch (e) {
      // handle error
      console.error('getAccount', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] getAccount`, e);
      return e;
    }
  }

  getDealer = async ():Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getDealer`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/dealer`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getDealer', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] getDealer`, e);
      return e;
    }
  }

  updateDealer = async (dealer:Dealer):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] updateDealer`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({...dealer}),
      };

      const ret = await doFetch(`${baseURL}v1/auth/updateDealer`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('updateDealer', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] updateDealer`, e);
      return e;
    }
  }

  checkRequest = async ():Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] checkRequest`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/check-request`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('checkRequest', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] checkRequest`, e);
      return e;
    }
  }

  saveRequest = async (eventRequest:D4UREvent):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] saveRequest`);

    try {
      stores.ui.setIsSavingEvent(true);
        const { requested_date } = eventRequest;
        const formattedEventRequest = {
            ...eventRequest,
        };
        if (requested_date) {
            const adjustedDate = moment(requested_date).add(12, 'hour').toDate();
            formattedEventRequest.requested_date = adjustedDate;
        }
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify(formattedEventRequest),
      };

      const ret = await doFetch(`${baseURL}v1/event/create-request`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsSavingEvent(false);
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('saveRequest', e);
      stores.ui.setIsSavingEvent(false);
      console.error(`[FordD4URAPI] saveRequest`, e);
      return e;
    }
  }

  getEvents = async ():Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getEvents`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/list`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getEvents', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] getEvents`, e);
      return e;
    }
  }

  getEventRequest = async (event_request_id:string):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getEventRequest`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/request/${event_request_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getEventRequest', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] getEventRequest`, e);
      return e;
    }
  }

  submitRequest = async (event_request_id:number):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] submitRequest`);

    try {
      stores.ui.setIsSavingEvent(true);

      const requestOptions: RequestInit = {
        method: 'POST',
      };

      const ret = await doFetch(`${baseURL}v1/event/event_request/submit/${event_request_id}`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsSavingEvent(false);
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('submitRequest', e);
      stores.ui.setIsSavingEvent(false);
      console.error(`[FordD4URAPI] submitRequest`, e);
      return e;
    }
  }

  saveEventPlan = async (eventPlan:any):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] saveEventPlan`);
    delete eventPlan.edit_log;

    const formData  = new FormData();
    for(const name in eventPlan) {
      eventPlan[name] && formData.append(name, eventPlan[name]);
    }

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: formData,
      };

      const ret = await doMultipart(`${baseURL}v1/event/event_request/${eventPlan.event_request_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('saveEventPlan', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] saveEventPlan`, e);
      return e;
    }
  };

    saveReimbursement = async (reimbursementInfo:any):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] saveEventPlan`);
    delete reimbursementInfo.edit_log;

    const formData  = new FormData();

    for(const name in reimbursementInfo) {
        reimbursementInfo[name] && formData.append(name, reimbursementInfo[name]);
        }
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: formData,
      };

      const ret = await doMultipart(`${baseURL}v1/event/event_reimbursement/${reimbursementInfo.event_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('saveEventPlan', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] saveEventPlan`, e);
      return e;
    }
  }

  getRespondents = async (event_id:string):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getRespondents`);
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/list-respondents/${event_id}`, requestOptions);
      return ret;
    } catch (e) {
      console.error('getRespondents', e);
      console.error(`[FordD4URAPI] getRespondents`, e);
      return e;
    }
    
  }

  send2FAEmail = async (contactEmail:string):Promise<D4URFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] send2FAEmail`);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      const ret = await doFetch(`${baseURL}v1/event/send-2fa-email/${contactEmail}`, requestOptions);
      return ret;
    } catch (e) {
      console.error('send2FAEmail', e);
      console.error(`[FordD4URAPI] send2FAEmail`, e);
      return e;
    }
  }
//   getBillingInfo = async ():Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] getBillingInfo`);
//     if (stores.login.dealer && stores.login.dealer.accounts && !stores.ui.isLoggingOut) {
//       let selectedAccount = stores.login.selectedAccountID;

//       try {
//         stores.ui.setIsGettingBilling(true);

//         const requestOptions: RequestInit = {
//           method: 'GET',
//         };

//         const ret = await doFetch(`${baseURL}accounts/${selectedAccount}/billing_info`, requestOptions);
//         stores.ui.setIsGettingBilling(false);

//         if (ret.response.status === 403) {
//           console.error(`[FordD4URAPI] getBillingInfo not logged in`, ret.response);
//           stores.ui.setAccount();
//           stores.login.doLogout();
//           // services.nav.setLoginRoot();
//         }

//         console.log('ret.result', ret.result);
//         const billingInfo = ret.result as BillingInfo;
//         console.log('ret.result.sensorsAvailableToInstall', billingInfo.sensorsAvailableToInstall);
//         console.log('ret.result.subscriptions.length', billingInfo.subscriptions.length);

//         if (billingInfo && billingInfo.sensorsAvailableToInstall && billingInfo.subscriptions.length) {
//           stores.ui.setBillingExists(true)
//           return true;
//         } else {
//           stores.ui.setBillingExists(false)
//           console.error(`[FordD4URAPI] getBillingInfo error`, ret.response);
//           return false;
//         }
//       } catch (e) {
//         // handle error
//         stores.ui.setIsGettingBilling(false);
//         // services.nav.dismissAllOverlays();
//         console.error(`[FordD4URAPI] getBillingInfo error`, e);
//         return;
//       }
//     }
//   }

//   getAccountsList = async (userId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] getAccountsList`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'GET',
//       };

//       const ret = await doFetch(`${baseURL}user/${userId}`, requestOptions);
//       if (ret.result) {
//         console.log('getAccountsList RESULT:', ret.result);

//         return ret;
//       } else {
//         console.error('getAccountsList', ret);
//       }
//     } catch (e) {
//       // handle error
//       console.error('getAccountsList', e);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] getAccountsList`, e);
//       return e;
//     }
//   }

//   getExpectedTimesData = async (locationID: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] getExpectedTimesData`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'GET',
//       };
//       const ret = await doFetch(`${baseURL}location/${locationID}/state_config`, requestOptions);
//       if (ret.result) {
//         const expectedTimesData = (ret.result as LocationStateConfig).config
//         stores.ui.setExpectedTimesData(expectedTimesData);
//         return ret;
//       } else {
//         console.error('getExpectedTimesData', ret);
//       }
//     } catch (e) {
//       console.error('getExpectedTimesData', e);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] getExpectedTimesData`, e);
//       return e;
//     }
//   }

//   updateExpectedTimes = async (locationID: number, newUsageConfig: object):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] updateExpectedTimes`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'PUT',
//         body: JSON.stringify({config : newUsageConfig})
//       };
//       const ret = await doFetch(`${baseURL}location/${locationID}/state_config`, requestOptions);
//       return ret;
//     } catch (e) {
//       console.error('updateExpectedTimes', e);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] updateExpectedTimes`, e);
//       return e;
//     }
//   }

//   getSensorData = async (locationID:number):Promise<D4URFetchResult | void> => {
//     console.log(`[FordD4URAPI] getSensorData`);
//     console.log('getSensorData:', stores.login.dealer);
//     if (!stores.login.dealer) {
//       return;
//     }
//     try {
//       stores.ui.setIsGettingAccount(true);

//       const requestOptions: RequestInit = {
//         method: 'GET',
//       };

//       const ret = await doFetch(`${baseURL}location/${locationID}/data?=useCumulativeEvents=false&downsample=5.minute`, requestOptions, true);
//       stores.ui.setIsGettingAccount(false);

//       if (ret.response.status === 403) {
//         console.error('getSensorData', 'User not logged in');
//         stores.ui.setAccount();
//         stores.login.doLogout();
//         // services.nav.setLoginRoot();
//       }

//       if (ret.result) {
//         return ret;
//       } else {
//         console.error('getSensorData', ret);
//       }
//     } catch (e:unknown) {
//       // handle error
//       console.error('getSensorData', e);
//       stores.ui.setIsGettingAccount(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] getSensorData`, e);
//       throw e;
//     }
//   }

//   getSensorEvents = async (sensorId: number, locationId:number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] getSensorEvents`);
//     // getSensorEvents = async () => {
//     try {
//       // stores.ui.setIsGettingAccount(true);

//       const requestOptions: RequestInit = {
//         method: 'GET',
//       };

//       const ret = await doFetch(`${baseURL}/location/${locationId}/events?sensor=${sensorId}`, requestOptions, true);
//       // stores.ui.setIsGettingAccount(false);
//       if (ret.response.status === 403) {
//         console.error('getSensorEvents', 'User not logged in');
//         stores.ui.setAccount();
//         stores.login.doLogout();
//         // services.nav.setLoginRoot();
//       }
//       // console.log('SENSOR EVENTS', Object.keys(ret), ret.result.events);

//       if (ret.result && (ret.result as SensorEventResponse).events) {
//         // stores.ui.setSensorEvents(ret.result.events)
//         return ret;
//       } else {
//         console.error('getSensorData', ret);
//       }
//     } catch (e) {
//       // handle error
//       console.error('getSensorData', e);
//       stores.ui.setIsGettingAccount(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] getSensorEvents`, e);
//       return e;
//     }
//   }

//   getUser = async (userId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] getUser`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'GET',
//       };
//       const ret = await doFetch(`${baseURL}user/${userId}`, requestOptions);
//       // stores.ui.setMemberList(ret.result.members)
//       // stores.ui.setInvitesList(ret.result.invites)
//       console.log('GET USE RET', ret.result);
//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('getUser', e);
//       console.error(`[FordD4URAPI] getUser`, e);
//       return e;
//     }
//   }

//   doVerify = async (email: string, code: string):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] doVerify`);
//     try {
//       stores.ui.setIsLoggingIn(true);

//       const requestOptions: RequestInit = {
//         method: 'POST',
//         body: JSON.stringify({email, code}),
//       };

//       const ret = await doFetch(`${baseURL}login/verify`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsLoggingIn(false);
//         // services.nav.dismissAllOverlays();
//       }, 1000);

//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('doVerify', e);
//       stores.ui.setIsLoggingIn(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] doVerify`, e);
//       return e;
//     }
//   }

//   doCreateUser = async ():Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] doCreateUser`);
//     try {
//       // stores.ui.setIsLoggingIn(true);
//       const requestOptions: RequestInit = {
//         method: 'POST',
//         body: JSON.stringify({
//           name: stores.ui.acceptInviteFirstName + ' ' + stores.ui.acceptInviteLastName,
//           email: stores.ui.acceptInviteEmail,
//           password: stores.ui.acceptInvitePass,
//           inviteCode: stores.ui.acceptInviteCode,
//         }),
//       };

//       const ret = await doFetch(`${baseURL}user`, requestOptions);
//       console.log('CREATE USER RET:', ret);
//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('createUser error', e);
//       // stores.ui.setIsLoggingIn(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] doCreateUser`, e);
//       return e;
//     }
//   }

//   sendResetPasswordCode = async (email: string):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] sendResetPasswordCode`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'POST',
//         body: JSON.stringify({email}),
//       };
//       const ret = await doFetch(`${baseURL}reset_password/send_code`, requestOptions);
//       console.log('RET //////', ret);
//       if (ret.result && (ret.result as ErrorResult).errors) {
//         return false;
//       }
//       return true;
//     } catch (e) {
//       // handle error
//       console.error('sendResetPassCode error', e);
//       console.error(`[FordD4URAPI] sendResetPasswordCode`, e);
//       // services.nav.dismissAllOverlays();
//       return e;
//     }
//   }

//   doResetPassword = async (email: string, code: number, newPassword: string):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] doResetPassword`);

//     try {
//       const requestOptions: RequestInit = {
//         method: 'POST',
//         body: JSON.stringify({
//           email,
//           oneTimeCode: code,
//           newPassword
//         }),
//       };
//       const ret = await doFetch(`${baseURL}/reset_password`, requestOptions);
//       // if (ret.result && ret.result.errors) {
//       //   return false;
//       // }
//       console.log('RESET PASS RET', ret);

//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('sendResetPassCode error', e);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] doResetPassword`, e);
//       return e;
//     }
//   }

//   doAddLocation = async (name:string, timezone: string, lat:number, long:number):Promise<AddLocationResult> => {
//     console.log(`[FordD4URAPI] doAddLocation`);
//     const selectedAccount = stores.login.dealer!.accounts?.find(account => account.id === stores.login.selectedAccountID)

//     try {
//       stores.ui.setIsAPIProcessing(true);

//       const requestOptions: RequestInit = {
//         method: 'POST',
//         body: JSON.stringify({
//           id: 0,
//           timezone,
//           lat,
//           long,
//           name,
//           address: '',
//         }),
//       };

//       const ret = await doFetch(`${baseURL}account/${selectedAccount!.id}/location`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//       }, 1000);

//       return ret as AddLocationResult;
//     } catch (e) {
//       // handle error
//       console.error('doAddLocation', e);
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] doAddLocation`, e);
//       throw e;
//     }
//   }

//   doAddSensor = async (locationId:number, sensorName: string, sensorParticleId: string):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] doAddSensor`);

//     try {
//       stores.ui.setIsAPIProcessing(true);

//       const requestOptions: RequestInit = {
//         method: 'POST',
//         body: JSON.stringify({
//           name: sensorName,
//           coreId: sensorParticleId,
//         }),
//       };

//       const ret = await doFetch(`${baseURL}location/${locationId}/sensor`, requestOptions, false);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//       }, 1000);

//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('doAddSensor', e);
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] doAddSensor`, e);
//       return e;
//     }
//   }

//   getMembershipData = async (accountId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] getMembershipData`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'GET',
//       };
//       const ret = await doFetch(`${baseURL}accounts/${accountId}/membership`, requestOptions);
//       stores.ui.setMemberList((ret.result as MembershipDataResult).members)
//       stores.ui.setInvitesList((ret.result as MembershipDataResult).invites)
//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('getMembershipData', e);
//       console.error(`[FordD4URAPI] getMembershipData`, e);
//       return e;
//     }
//   }

//   revokeInvitation = async (accountId: number, inviteId: number ):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] revokeInvitation`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'DELETE',
//       };

//       return await doFetch(`${baseURL}accounts/${(accountId)}/invitations/${(inviteId)}`, requestOptions);
//     } catch (e) {
//       // handle error
//       console.error('revoking invitation error', e);
//       console.error(`[FordD4URAPI] revokeInvitation`, e);
//       return e;
//     }
//   }

//   removeUser = async (accountId: number, userId: number ):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] removeUser`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'DELETE',
//       };
//       await doFetch(`${baseURL}accounts/${(accountId)}/membership/${(userId)}`, requestOptions);
//       return;
//     } catch (e) {
//       // handle error
//       console.error('error removing user', e);
//       console.error(`[FordD4URAPI] removeUser`, e);
//       return e;
//     }
//   }

//   sendInvitation = async (accountId: number, phoneNumber: string, phoneRegion: string):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] sendInvitation`);
//     try {
//       const requestOptions: RequestInit = {
//         method: 'PUT',
//         body: JSON.stringify({phoneNumber, phoneRegion})
//       };

//       const ret = await doFetch(`${baseURL}accounts/${accountId}/invitations`, requestOptions);
//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('do invite error !!!!!:', e);
//       console.error(`[FordD4URAPI] sendInvitation`, e);
//       return e;
//     }
//   }

//   getNotifications = async (userId: number, accountId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] getNotifications`);
//     let toSecond = Math.round((new Date().getTime()) / 1000);
//     let fromSecond = Math.round(toSecond - (86400*7));
//     let limit = 100;

//     try {
//       const requestOptions: RequestInit = {
//         method: 'GET',
//       };

//       const ret = await doFetch(`${baseURL}user/${userId}/notifications?fromSecond=${fromSecond}&toSecond=${toSecond}&accountId=${accountId}&limit=${limit}`, requestOptions);
//       stores.ui.setNotificationsList((ret.result as NotificationsResult).notifications);
//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('do getNotifications error !!!!!:', e);
//       console.error(`[FordD4URAPI] getNotifications`, e);
//       return e;
//     }
//   }

//   updateLocation = async (locationId: number, locationName: string, locationTimezone: string):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] updateLocation`);
//     try {
//       stores.ui.setIsAPIProcessing(true);

//       const requestOptions: RequestInit = {
//         method: 'PATCH',
//         body: JSON.stringify({
//           name: locationName,
//           timezone: locationTimezone,
//         }),
//       };

//       const ret = await doFetch(`${baseURL}location/${locationId}`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//         this.getAccount();
//         stores.ui.toggleEditLocationModal(false);
//         stores.ui.setLocationName('');
//         stores.ui.setEditLocationBtnDisabled(true);
//       }, 1000);

//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('updateLocation Error:', e);
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] updateLocation`, e);
//       return e;
//     }
//   }

//   pauseActivityChecks = async (locationId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] pauseActivityChecks`);
//     try {
//       stores.ui.setIsAPIProcessing(true);
//       const requestOptions: RequestInit = {
//         method: 'POST',
//       };

//       const ret = await doFetch(`${baseURL}location/${locationId}/state/pause`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//         this.getAccount();
//       }, 1000);
//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('pause activity checks error:', e);
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] pauseActivityChecks`, e);
//       return e;
//     }
//   }

//   unpauseActivityChecks = async (locationId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] unpauseActivityChecks`);
//     try {
//       stores.ui.setIsAPIProcessing(true);
//       const requestOptions: RequestInit = {
//         method: 'POST',
//       };

//       const ret = await doFetch(`${baseURL}location/${locationId}/state/unpause`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//         this.getAccount();
//       }, 1000);
//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('pause activity checks error:', e);
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] unpauseActivityChecks`, e);
//       return e;
//     }
//   }

//   snoozeActivityZone = async (locationId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] snoozeActivityZone`);
//     try {
//       stores.ui.setIsAPIProcessing(true);
//       const requestOptions: RequestInit = {
//         method: 'POST',
//       };

//       await doFetch(`${baseURL}location/${locationId}/state/suppress`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//         this.getAccount();
//       }, 1000);
//     } catch (e) {
//       // handle error
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] snoozeActivityZone`, e);
//       return e;
//     }
//   }

//   deleteLocation = async (locationId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] deleteLocation`);
//     try {
//       stores.ui.setIsAPIProcessing(true);
//       const requestOptions: RequestInit = {
//         method: 'DELETE',
//       };
//       await doFetch(`${baseURL}location/${locationId}`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//         this.getAccount();
//       }, 1000);
//     } catch (e) {
//       // handle error
//       console.error('delete location error:', e);
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] deleteLocation`, e);
//       return e;
//     }
//   }

//   deleteSensor = async (sensorId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] deleteSensor`);
//     try {
//       stores.ui.setIsAPIProcessing(true);
//       const requestOptions: RequestInit = {
//         method: 'DELETE',
//       };
//       await doFetch(`${baseURL}sensor/${sensorId}`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//         // startApp()
//       }, 1000);
//     } catch (e) {
//       // handle error
//       console.error('delete sensor error:', e);
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] deleteSensor`, e);
//       return e;
//     }
//   }

//   updateSensor = async (sensorName: string, sensorId: number):Promise<D4URFetchResult | unknown | undefined> => {
//     console.log(`[FordD4URAPI] updateSensor`);
//     try {
//       stores.ui.setIsAPIProcessing(true);

//       const requestOptions: RequestInit = {
//         method: 'PATCH',
//         body: JSON.stringify({
//           name: sensorName,
//         }),
//       };

//       const ret = await doFetch(`${baseURL}sensor/${sensorId}`, requestOptions);
//       setTimeout(() => {
//         stores.ui.setIsAPIProcessing(false);
//         // services.nav.dismissAllOverlays();
//         stores.ui.setSensorName('');
//         stores.ui.setEditSensorBtnDisabled(true);
//         stores.ui.toggleEditSensorModal(false);
//         // startApp()
//       }, 1000);

//       return ret;
//     } catch (e) {
//       // handle error
//       console.error('updateSensor Error:', e);
//       stores.ui.setIsAPIProcessing(false);
//       // services.nav.dismissAllOverlays();
//       console.error(`[FordD4URAPI] updateSensor`, e);
//       return e;
//     }
//   }

//   // uploadLogs = async ():Promise<D4URFetchResult | unknown | undefined> => {
//   //   console.log(`[FordD4URAPI] uploadLogs`);
//   //   try {
//   //     stores.ui.setIsAPIProcessing(true);
//   //     const requestOptions: RequestInit = {
//   //       method: 'POST',
//   //       headers: { 'Content-Type': 'text/plain' },
//   //       body: await services.logging.getLogFileContents(),
//   //     };

//   //     console.log('[uploadLogs]', 'submitting', requestOptions);
//   //     const selectedAccount = stores.login.dealer!.accounts?.find(account => account.id === stores.login.selectedAccountID);
//   //     const accountId = selectedAccount!.id;
//   //     const accountEmail = selectedAccount!.accountOwnerEmail;
//   //     const dateStamp = moment().format('YYYYMMDD')
//   //     // file name format from Todd: "log-\(email!)-\(accountId)-\(dateString).txt"
//   //     const fileName = `log-${accountEmail}-${accountId}-${Platform.OS}-${dateStamp}.txt`;
//   //     const response = await fetch(`${baseURL}deviceLogs/${fileName}`, requestOptions);
//   //     console.log('[uploadLogs', 'response from fetch', response)
//   //     let result:ErrorResult;
//   //     try {
//   //       result = await response.json();
//   //     } catch (e:unknown) {
//   //       console.log('uploadLogs error', e)
//   //       console.error(`[FordD4URAPI] uploadLogs doFetch`, e);
//   //       result = {};
//   //     }
//   //     if (result.errors) {
//   //       return {status: false, error: result.errors[0]}
//   //     }
//   //     // return {response, result};
//   //     return {status: true};
//   //   } catch (e) {
//   //     // handle error
//   //     console.error('uploadLogs Error:', e);
//   //     stores.ui.setIsAPIProcessing(false);
//   //     // services.nav.dismissAllOverlays();
//   //     console.error(`[FordD4URAPI] uploadLogs`, e);
//   //     // return e;
//   //     return {status: false, error: e};
//   //   }
//   // }

//   // registerNotifications = async (userId: number, sendTest: boolean, model: string, name: string, token: string):Promise<D4URFetchResult | unknown | undefined> => {
//   //   console.log(`[FordD4URAPI] registerNotifications`);

//   //   try {
//   //     const requestOptions: RequestInit = {
//   //       method: 'PUT',
//   //       headers: { 'Content-Type': 'application/json' },
//   //       body: JSON.stringify({
//   //         sendTest : sendTest || false,
//   //         model,
//   //         name,
//   //         token,
//   //       }),
//   //     };

//   //     const endpoint = Platform.OS === 'ios' ? 'apns' : 'fcm';

//   //     const ret = await doFetch(`${baseURL}user/${userId}/push_devices/${endpoint}`, requestOptions);
//   //     // endpoint doesn't return anything useful
//   //     console.error(`[FordD4URAPI] registerNotifications success`);

//   //     return ret;
//   //   } catch (e) {
//   //     console.error(`[FordD4URAPI] registerNotifications error`, e);
//   //     return e;
//   //   }
//   // }
}

export default new D4URService();
