import React, { useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error, Label } from "@progress/kendo-react-labels";
import {
    emailValidator,
    requiredValidator,
    phoneValidator,
    address1Validator,
    cityValidator,
    zipValidator,
    nameValidator,
} from '../validators';
import { states } from '../resources/states';
import '../styles/_elig_form.scss';

// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';

import { AppContext } from './../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import { MaskedTextBox } from 'src/components/form/MaskedTextBox';
import { useHistory } from 'react-router';
import { DropDownList } from 'src/components/form/DropDownList';
import { DatePicker } from 'src/components/form/DatePicker';
import moment from 'moment';
import { Checkbox } from 'src/components/form/Checkbox';
import { Loader } from '@progress/kendo-react-indicators';
import { FieldWrapper } from '@progress/kendo-react-form';

const EligibilityFormScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const { language } = localizationService;

    const [formError, setFormError] = useState<string>();
    const { d4ur } = useServices();
    let history = useHistory();
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [displayOtherAddressForm, setDisplayOtherAddressForm] = React.useState<boolean>(false);

    const onSubmit = React.useCallback(
        async (dataItem) => {
            setProcessing(true);

            dataItem.season_id = 9; // TODO: season should be dynamic

            // format dates properly
            if (dataItem.start_date) {
                dataItem.start_date = moment(dataItem.start_date).format('YYYY-MM-DD');
            }

            // other json data
            dataItem.event_custom_data = {
                additional_schools: dataItem.additional_schools,
                kit_location: dataItem.kit_location,
                event_location_type: dataItem.event_location_type,
            };

            if (!displayOtherAddressForm) {
                dataItem.event_address = '';
                dataItem.event_city = '';
                dataItem.event_state = '';
                dataItem.event_postal_code = '';
            }

            const eventRequest: D4UREvent = dataItem;

            const call: any = await d4ur.saveRequest(eventRequest);
            if (call.result.errors || call.result.error_message) {
                console.log('[EligibilityFormScreen]', 'Event Request Error');
                setFormError(call.result.errors ? call.result.join('\n') : call.result.error_message);
                setProcessing(false);
            } else {
                console.log('[EligibilityFormScreen]', 'event requested');
                history.push('/events');
            }
        },
        [d4ur, displayOtherAddressForm, history]
    );

    const ref: any = React.useRef(null);
    const dateRef: any = React.useRef(null);

    const handleOnWheel = (event: any) => {
        event.stopPropagation();
    };

    React.useEffect(() => {
        ref?.current?.element.addEventListener('wheel', handleOnWheel);
        dateRef?.current?.element.addEventListener('wheel', handleOnWheel);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ref?.current?.element.removeEventListener('wheel', handleOnWheel);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            dateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
        };
    }, []);


    console.log(' date??', moment().add(10, 'days').toDate());

    return (
        <>
            <div id="Eligibility" className="eligibility-page">
                <div className="eligibility-content">
                    <div className="form-container" style={{ maxWidth: 700 }}>
                        <h3 className="request-title">{localizationService.toLanguageString('custom.eligibilityRequest', 'eligibilityRequest')}</h3>
                        <p className="request-text">
                        Please fill out the form below, providing information about the event date, and school. Please make sure that you have contacted your school(s) and have agreed on an event date prior to submitting this form.
                        Please complete all fields of the event request form. For official rules and details please read the <a href="/terms-eligibility" target='_blank'>Terms & Conditions</a>. Press the "Submit Event Request" button when complete.
                            {/* You can review our <a href="/organization-guidelines" target="_blank">Organization Guidelines here</a></p> */}
                        </p>
                    {
                        processing ? <div style={{ textAlign: 'center' }}><Loader type="infinite-spinner" size="large" /></div>
                            :
                            <div>
                                <Form
                                    onSubmit={onSubmit}
                                    initialValues={{
                                        ...formValues,
                                        tax_id: '',
                                    }}
                                    render={(formRenderProps) => (
                                        <FormElement horizontal={true}>
                                            {formError && <Error>{formError}</Error>}
                                            <FieldWrapper>
                                                <Label >{'Enrollment Period:'}</Label>
                                                <div className={'k-form-field-wrap'}>
                                                   <Label style={{ paddingTop: '5px', fontFamily: 'FordF1-Semibold'}}>Fall 2024</Label>
                                                </div>
                                            </FieldWrapper>
                                            <Field
                                                id={'start_date'}
                                                name={'start_date'}
                                                childRef={dateRef}
                                                label={localizationService.toLanguageString('custom.event_date', 'event_date')}
                                                format="MM/dd/yyyy"
                                                // if today + 10 days is before the first event date, then min date is the first event date. Otherwise, if today is inside the event date range, then min date is 10 days from today.
                                                min={moment().add(10, 'days').isBefore('2024-08-15') ? moment('2024-08-15').toDate() : moment().add(10, 'days').toDate()}
                                                max={moment('2024-11-17').toDate()}
                                                validator={requiredValidator}
                                                component={DatePicker}
                                                hint='Your event date must be between August 15th and November 17th and be at least 10 days away.'
                                            />
                                            <Field
                                                id={'event_location_type'}
                                                name={'event_location_type'}
                                                data={['The School', 'The Dealership', 'Other']}
                                                label={`Event Location`}
                                                validator={requiredValidator}
                                                hint={`Please select the option that best describes the location of your event. Please note that your event location must be held within your dealership's PMA/locality zone. Past approval of events held at locations outside of your dealership's PMA/locality does not guarantee future event approval in the same location.`}
                                                component={DropDownList}
                                                onChange={(e) => {
                                                    if (e.target.value === 'Other') {
                                                        setDisplayOtherAddressForm(true)
                                                    } else {
                                                        setDisplayOtherAddressForm(false)
                                                    }
                                                }}
                                            />
                                            <Field
                                                id={'agree_pma'}
                                                name={'agree_pma'}
                                                label={`${localizationService.toLanguageString('custom.pma', 'terms')}`}
                                                checkboxText={`${localizationService.toLanguageString('custom.agree_pma_terms', 'agree_pma_terms')}`}
                                                validator={requiredValidator}
                                                required={true}
                                                component={Checkbox}
                                            />
                                            { displayOtherAddressForm && 
                                            <> 
                                                <Field
                                                id={'event_address'}
                                                name={'event_address'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.eventAddress', 'address')}
                                                validator={address1Validator}
                                                required={true}
                                                component={Input}
                                                />
                                                <Field
                                                    id={'event_city'}
                                                    name={'event_city'}
                                                    maxLength={45}
                                                    label={localizationService.toLanguageString('custom.eventCity', 'city')}
                                                    validator={cityValidator}
                                                    required={true}
                                                    component={Input}
                                                />
                                                <Field
                                                    component={DropDownList}
                                                    id={'event_state'}
                                                    name={'event_state'}
                                                    label={localizationService.toLanguageString('custom.eventState', 'state')}
                                                    data={states}
                                                    validator={requiredValidator}
                                                    required={true}
                                                />
                                                <Field
                                                    id={'event_postal_code'}
                                                    name={'event_postal_code'}
                                                    label={localizationService.toLanguageString('custom.eventZip', 'zip')}
                                                    validator={zipValidator}
                                                    required={true}
                                                    mask="00000"
                                                    component={MaskedTextBox}
                                                /> 
                                            </> }
                                            <Field
                                                id={'name'}
                                                name={'name'}
                                                maxLength={100}
                                                label={localizationService.toLanguageString('custom.charity_name', 'charity_name')}
                                                validator={requiredValidator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'address_1'}
                                                name={'address_1'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.schoolAddress', 'schoolAddress')}
                                                validator={address1Validator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'address_2'}
                                                name={'address_2'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.address2', 'address2')}
                                                component={Input}
                                            />
                                            <Field
                                                id={'city'}
                                                name={'city'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.city', 'city')}
                                                validator={cityValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                component={DropDownList}
                                                id={'state'}
                                                name={'state'}
                                                label={localizationService.toLanguageString('custom.state', 'state')}
                                                data={states}
                                                validator={requiredValidator}
                                                required={true}
                                            />
                                            <Field
                                                id={'postal_code'}
                                                name={'postal_code'}
                                                label={localizationService.toLanguageString('custom.zip', 'zip')}
                                                validator={zipValidator}
                                                required={true}
                                                mask="00000"
                                                component={MaskedTextBox}
                                            />
                                            {/* <Field
                                                id={'website'}
                                                name={'website'}
                                                maxLength={255}
                                                label={localizationService.toLanguageString('custom.website', 'website')}
                                                validator={requiredValidator}
                                                component={Input}
                                            /> */}
                                            <Field
                                                id={'contact_first_name'}
                                                name={'contact_first_name'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.contact_first_name', 'contact_first_name')}
                                                validator={nameValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'contact_last_name'}
                                                name={'contact_last_name'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.contact_last_name', 'contact_last_name')}
                                                validator={nameValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'contact_phone_number'}
                                                name={'contact_phone_number'}
                                                label={localizationService.toLanguageString('custom.org_contact_phone', 'org_contact_phone')}
                                                validator={phoneValidator}
                                                required={true}
                                                mask="000-000-0000"
                                                placeholder={'e.g.: 000-000-0000'}
                                                component={MaskedTextBox}
                                            />
                                            <Field
                                                id={'contact_email'}
                                                name={'contact_email'}
                                                type={'email'}
                                                maxLength={100}
                                                placeholder={'e.g.: peter@gmail.com'}
                                                label={localizationService.toLanguageString('custom.org_contact_email', 'org_contact_email')}
                                                validator={emailValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'funding_purpose'}
                                                name={'funding_purpose'}
                                                maxLength={1000}
                                                label={localizationService.toLanguageString('custom.org_description', 'org_description')}
                                                hint={localizationService.toLanguageString('custom.org_description_hint', 'org_description_hint')}
                                                validator={requiredValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'check_payable'}
                                                name={'check_payable'}
                                                maxLength={1000}
                                                label={'Check Payable To'}
                                                hint={'All checks will be sent to the Dealer Contact at the Dealership Address provided'}
                                                validator={requiredValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            {/* <Field
                                                id={'check_location'}
                                                name={'check_location'}
                                                data={['The School address provided', 'The Dealership address provided']}
                                                label={`I want my check sent to…`}
                                                validator={requiredValidator}
                                                component={DropDownList}
                                            /> */}
                                            <Field
                                                id={'kit_location'}
                                                name={'kit_location'}
                                                data={[ 'The Dealership address provided', 'The School address provided' ]}
                                                label={`Kit Shipment Location`}
                                                validator={requiredValidator}
                                                component={DropDownList}
                                            />
                                            {/* <Field
                                                id={'additional_schools'}
                                                name={'additional_schools'}
                                                maxLength={1000}
                                                label={`Additional Schools/Organizations`}
                                                hint={`If you are working with more than one school, please detail the additional schools here.`}
                                                component={TextArea}
                                            /> */}
                                              <Field
                                                id={'secondary_school'}
                                                name={'secondary_school'}
                                                maxLength={100}
                                                // placeholder={'e.g.: peter@gmail.com'}
                                                label={localizationService.toLanguageString('custom.secondary_school', 'Secondary School Name')}
                                                required={false}
                                                component={Input}
                                                hint={`If you are working with an additional school, please enter the name here. Otherwise, please leave this field blank.`}
                                            />
                                            {/* <Field
                                                id={'event_location_name'}
                                                name={'event_location_name'}
                                                data={['Dealership', 'Other']}
                                                maxLength={255}
                                                label={localizationService.toLanguageString('custom.event_location_name', 'event_location_name')}
                                                validator={requiredValidator}
                                                hint={localizationService.toLanguageString('custom.event_location_hint', 'event_location_hint')}
                                                component={Input}
                                            /> */}
                                            <h3 className="waiver-title">Electronic Waiver Acknowledgement</h3>
                                            <p className="request-text"><span className='waiver_bold_text'>Electronic Waiver Process:</span> In an effort to continuously advance the program, all events in 2024 will be using electronic waivers to capture participant test-drive information. Please make sure you review the electronic waiver process and prepare dealership / volunteer staff. Training is <span className='waiver_bold_text'>MANDATORY</span> in order to be prepared for this process. Dealers who have not properly trained will be subject to event cancellation. By enrolling in a Drive 4 UR School event, you are agreeing to utilize electronic waivers.</p>
                                            <p className="request-text">Note: <span className='waiver_bold_text'>Ford will NOT be sending devices for electronic waiver events.</span></p>
                                            <Field
                                                id={'agree_digital'}
                                                name={'agree_digital'}
                                                label=""
                                                checkboxText="I understand that my event will be assigned electronic waivers and I will NOT receive devices to administer."
                                                validator={requiredValidator}
                                                required={true}
                                                component={Checkbox}
                                            />
                                            <Field
                                                id={'agree_training'}
                                                name={'agree_training'}
                                                label=""
                                                checkboxText="I understand that I must be fully trained on the electronic waiver process or my event may be subject to cancellation."
                                                validator={requiredValidator}
                                                required={true}
                                                component={Checkbox}
                                            />
                                            <Field
                                                id={'agree_terms'}
                                                name={'agree_terms'}
                                                label={`${localizationService.toLanguageString('custom.terms', 'terms')}`}
                                                checkboxText={`${localizationService.toLanguageString('custom.agree_eligibility_terms', 'agree_eligibility_terms')}`}
                                                // hint={<a href="/terms-eligibility" target="_blank">{localizationService.toLanguageString('custom.read_eligibility_terms', 'read_eligibility_terms')}</a>}
                                                validator={requiredValidator}
                                                required={true}
                                                component={Checkbox}
                                            />
                                            <a className='terms_link' href="/terms-eligibility" target="_blank">{localizationService.toLanguageString('custom.read_eligibility_terms', 'read_eligibility_terms')}</a>
                                            <div className={'vertical_btn_container'}>
                                                <Button
                                                    primary={true}
                                                    type={'submit'}
                                                    disabled={!formRenderProps.allowSubmit}
                                                    className='standard_btn wide'
                                                >
                                                    {localizationService.toLanguageString('custom.submitEligibility', 'submitEligibility')}
                                                </Button>
                                                <Button  className='standard_btn wide cancel' onClick={() => history.push('/events')}>
                                                    {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                                </Button>
                                            </div>
                                        </FormElement>
                                    )}
                                />
                            </div>
                    }
                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />

        </>
    );
}

export default EligibilityFormScreen;
