import React, { useEffect, useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { emailValidator, requiredValidator } from '../validators';

import { AppContext } from './../AppContext'
import { useStores } from 'src/stores';
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import logo from "../assets/images/Ford_D4URS_Blue_Screen_RGB_v1.png";
import sideImage from "../assets/images/Login_Crop_v3.jpg";
import '../styles/_login.scss';

const LoginScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const { language } = localizationService;
    const history = useHistory();

    const [loginError, setLoginError] = useState<string>('');
    const { login } = useStores();
    const { d4ur } = useServices();

    let location = useLocation<Location>();

    let { from } = location.state as any || { from: { pathname: "/events" } };

    useEffect(() => {
        if (login.dealer) {
            console.log('[LoginScreen]', 'I think we are already logged in');
            d4ur.getAccount().then(() => {
                history.push('/events');
            });
        }
        // eslint-disable-next-line
    }, [])

    const onSubmit = React.useCallback(
        async (dataItem) => {
            login.setLoginEmail(dataItem.email);
            const call: any = await d4ur.doLogin(dataItem.email, dataItem.password);
            if (call.result.ERROR) {
                console.log('[LoginScreen]', 'Login Error');
                return setLoginError(call.result.message);
            }
            if (call.result.dealer) {
                console.log('[LoginScreen]', 'logged in, no 2fa required');
                login.setDealerToken(call.result.token)
                login.setDealer(call.result.dealer as Dealer);
                history.push(from);
            }
        },
        [login, from, history, d4ur]
    );

    return (
        <div id="Login" className="login_page">
            <div className='main'>
                <div className="left_content">
                    {/* <div className={'logo_container'}> */}
                        <img src={logo} alt={'Ford Drive 4 UR School Logo'} className='logo' onClick={() => {
                                        history.push("/");
                                    }} />
                    {/* </div> */}
                    <div className="form-container" style={{ maxWidth: 700 }}>
                            <h1 className="form_title">Log In</h1>
                            <Form
                                onSubmit={onSubmit}
                                initialValues={{
                                    ...formValues,
                                    email: login.loginEmail,
                                }}
                                render={(formRenderProps) => (
                                    <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                        {loginError && <Error>{loginError}</Error>}
                                        <Field
                                            id={'email'}
                                            name={'email'}
                                            type={'email'}
                                            placeholder={'e.g.: peter@gmail.com'}
                                            label={localizationService.toLanguageString('custom.email', 'Email')}
                                            validator={emailValidator}
                                            required={true}
                                            component={Input}
                                        />
                                        <Field
                                            id={'password'}
                                            name={'password'}
                                            type={'password'}
                                            label={localizationService.toLanguageString('custom.password', 'Password')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <div className='log_in_button'>
                                            <Button
                                                className={'log_in_btn'}
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {localizationService.toLanguageString('custom.logIn', 'Log In')}
                                            </Button>
                                        </div>

                                    </FormElement>
                                )}
                            />
                            <div className="sign_up_container">
                                <div className='forgot_pass_container'>
                                <p className='sign_up_text'>Don't have an account?</p>
                                <button 
                                        className='sign_up_btn' 
                                        onClick={() => {
                                            history.push("/signup");
                                        }}>
                                        Sign Up
                                    </button>
                                </div>
                                <div className='forgot_pass_container'>
                                    <button className='forgot_pass_btn' onClick={() => {
                                history.push("/password");
                            }}>
                                        Forgot Password?
                                    </button>
                                </div>
                            </div>
                    </div>
                </div>
                <div className='right_content'>
                    <img src={sideImage} alt={'a Ford vehicle parked behind a banner with the Drive 4 Your School logo'} className='right_img' />        
                </div>

            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
}

export default LoginScreen;
