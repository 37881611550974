import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../../assets/images/D4URS_FordOval_RGB_v2.png";
const BestPracticesScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;

    return (
        <div id="Profile" className="guidelines-page main-content">
            <img src={logo} alt={'Ford Drive 4 UR School Logo 100 years'} className={'logo'} />
            <div className="card-container">
                <div className="card-component">
                <h2>Driven to Give Event Best Practices</h2>
                    <ul>
                    <span className="bold">Pre-Event</span>
                        <li>Involve your benefitting charity organization and encourage them to send a representative to the event to engage attendees in their mission.
                            <ul>
                                <li>Store staff can answer any vehicle specific questions; volunteers from the organization may help staff the event as well.</li>
                                <li>Confirm that the store staff messages Lincoln services (Pickup and Delivery, etc) in addition to vehicles.</li>
                            </ul>
                        </li>
                        <li>Develop an invitation strategy and deploy invitations at least three weeks ahead of the event.
                            <ul>
                                <li>Be sure to include the registration link or QR code so attendees can fill out the pre-drive survey to save time at the event.</li>
                                <li>When sending invitations via email, make sure to send invites in small batches so they aren’t flagged as junk emails.</li>
                            </ul>
                        </li>
                        <li>Confirm that all required event elements are incorporated into the event.</li>
                        <li>Watch the Data Capture Training video to ensure you follow the data capture process.</li>
                    </ul>
                    <ul>
                    <span className="bold">On Site</span>
                        <li>Ensure that check-in staff are trained on the process.
                        </li>
                        <li>Note that if attendees show up without having registered for the event, they can register onsite using the QR code. Have a few QR codes printed and displayed for this purpose.
                        </li>
                        <li>Make sure to follow the steps outlined in the Data Capture Training video
                            <ul>
                                <li>All attendees who take a test-drive must be “checked out” to be eligible for a donation.</li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                    <span className="bold">Post-Event</span>
                        <li>Post pictures on store social media to recap the event and spread the word about your Driven to Give event.
                            <ul>
                                <li>Be sure to tag @Lincoln on posts.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
};

export default BestPracticesScreen;
