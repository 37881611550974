import { default as React, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import hashid from '../utils/hashid';
import { useLocalization } from "@progress/kendo-react-intl";
import { useServices } from "src/services";
import { Grid, GridCellProps, GridColumn, GridToolbar, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { useHistory } from "react-router";
import moment from "moment";
import { useStores } from "src/stores";
// @ts-ignore
import PrivacyBanner from "react-ford-ccpa-banner";

type RespondentsParams = {
  event_id: string;
};

const RespondentsList = () => {
  const { login } = useStores();
  const { d4ur } = useServices();
  let history = useHistory();
  const localizationService = useLocalization();
  const { language } = localizationService;
  const initialSort: Array<SortDescriptor> = [
    { field: "survey_date", dir: "desc" },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [respondents, setRespondents] = useState<Array<Respondent>>([]);
  const [sort, setSort] = React.useState(initialSort);

  const { event_id } = useParams<RespondentsParams>();

  const _export = React.useRef<ExcelExport | null>(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  useEffect(() => {
    const checkIfAuthenticated = async () => {
      const [decodedTimestamp] = await hashid.decode('lcc_2fa_code', login.twoFACode);
      if (!moment(decodedTimestamp).isSame(moment(), 'day')) {
        return history.push('/events')
      }
      return;
    }

    // if not authenticated or 2fa is expired, go home.
    if (!login.twoFACode) {
      history.push('/events')
    } else if (login.twoFACode) {
      checkIfAuthenticated();
    }
    setLoading(true)
    d4ur.getRespondents(event_id).then((ret) => {
      setRespondents((ret as D4URFetchResult).result.respondents)
      setLoading(false);
    })
      .catch((e: any) => {
        setRespondents([]);
        setError(e.message);
        setLoading(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLoadingSpinner = () => {
    return (
      <>
        <p style={{ textAlign: 'center', fontWeight: 'bolder' }}>Loading...</p>
      </>
    )
  }

  const renderErrorMsg = () => {
    return (
      <>
        <p style={{ textAlign: 'center', fontWeight: 'bolder', color: '#d51923' }}>There was an error fetching respondents: {error}</p>
      </>
    )
  }

  const surveyDateCell = (props: GridCellProps) => {
    const surveyDateRaw = props.dataItem.survey_date;
    return (
      <td>{moment(surveyDateRaw).format('MMMM Do yy')}</td>
    )
  };

  const renderRespondentsGrid = () => {
    return (
      <ExcelExport data={respondents} ref={_export}>
        <Grid
          data={orderBy(respondents, sort)}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
          style={{}} resizable={true} reorderable={true}>
          <GridToolbar>
            <button
              title="Export Excel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={excelExport}
            >
              Export to Excel
            </button>
          </GridToolbar>
          <GridColumn field="survey_date" title="Survey Date" cell={surveyDateCell} />
          <GridColumn field="first_name" title="First Name" />
          <GridColumn field="last_name" title="Last Name" />
        </Grid>
      </ExcelExport>
    )
  }

  return (
    <>
      <div id="Dashboard" className="dashboard-page main-content">
        <div className="card-container">
          <h3 className="card-title">
            Event Respondents
          </h3>
          {loading ? renderLoadingSpinner() : renderRespondentsGrid()}
          {!loading && error ? renderErrorMsg() : null}
        </div>
      </div>
      <PrivacyBanner
        containerStyle={{ padding: "10px 0 0", width: "100%" }}
        privacyContainerStyle={{ padding: "10px 0", width: "60%", marginLeft: '20%' }}
        showPrivacy={true}
        language={language}
        team={'ford'}
        imgStyle={{ paddingLeft: "30px" }}
        otherLinks={[
          { text: "CONTACT US", url: "/contact", target: '_blank' },
          { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
          { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
      ]}
      />
    </>
  );
};

export default observer(RespondentsList);
