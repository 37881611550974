import * as React from "react";

import { useHistory } from "react-router";
import { Button } from "@progress/kendo-react-buttons";
// import logo from "../assets/images/Ford_D4URS_Blue_Screen_RGB_v1.png";

const NotFound = () => {
  const history = useHistory();
  return (
    <>
      <div id="Home" className="home-page main-content not_found" style={{ height: '100vh'}}>
        <div className="content">
          {/* <img src={logo} alt={"Ford Drive 4 UR School Logo"} className={"logo"} /> */}
          <p className="">PAGE NOT FOUND</p>
          <div>
            <Button
              className="standard_btn"
              onClick={() => {
                history.push("/");
              }}
              style={{ marginTop: 20}}
            >
              <span className={"k-icon k-i-arrow-chevron-left"} />
              Home
              <span className={"k-icon k-i-arrow-chevron-right"} />
            </Button>

          </div>
        </div>
        
      </div>
    </>
  );
};

export default NotFound;
