import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

import "./App.scss";

Bugsnag.start({
  apiKey: "bfd16fffbeeb61566168d73cfde2c8d1",
  appVersion: "0.1.125",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
