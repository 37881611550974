/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useParams } from "react-router-dom";

import { useLocalization } from '@progress/kendo-react-intl';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import {
    CompositeFilterDescriptor,
    filterBy,
    FilterDescriptor,
} from "@progress/kendo-data-query";
import { Button } from '@progress/kendo-react-buttons';
import { Label, Error } from "@progress/kendo-react-labels";
import {requiredValidator} from '../validators';
import { states } from '../resources/states';
import raw_timezones from 'src/stores/timezones.json';

import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import { MaskedTextBox } from 'src/components/form/MaskedTextBox';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from 'src/components/form/DropDownList';
import { DropDownListFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { Upload } from 'src/components/form/Upload';
import { UploadFileInfo, UploadOnAddEvent, UploadOnProgressEvent, UploadOnRemoveEvent, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { DatePicker } from 'src/components/form/DatePicker';
import { TimePicker } from 'src/components/form/TimePicker';
import { NumericTextBox } from 'src/components/form/NumericTextBox';
import { Checkbox } from 'src/components/form/Checkbox';
import { Loader } from '@progress/kendo-react-indicators';

type EligibilityParams = {
    event_request_id: string;
};

const eventTypes = [
    {text: 'No test-drive or walkaround', value: 'basic'},
    {text: 'Test-drive', value: 'test_drive'},
    {text: 'Walkaround', value: 'walkaround'},
];

const EventPlanningFormScreen = () => {
        const {...formValues} = React.useContext(AppContext);
        const localizationService = useLocalization();

        const { d4ur } = useServices();
        let history = useHistory();
        const { event_request_id } = useParams<EligibilityParams>();

        const [ dialogDisplayed, setDialogDisplayed ] = useState<string>('none');
        const [ formDisplayed, setFormDisplayed ] = useState<string>('none');
        const [ formError, setFormError ] = useState<string>();

        const [thisEvent, setThisEvent] = useState<D4UREvent>();
        const [charity, setCharity] = useState<Charity>();
        // const [dealer, setDealer] = useState<Dealer>();
        const [planner, setPlanner] = useState<UserDetails>();
        const [timeZones, setTimeZones] = useState<string[]>();

        const [processing, setProcessing] = React.useState<boolean>(true);
        const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);

        const onAdd = (event: UploadOnAddEvent) => {
            console.log("onAdd: ", event.affectedFiles);

            setFiles(event.newState);
        };

        const onRemove = (event: UploadOnRemoveEvent) => {
            console.log("onRemove: ", event.affectedFiles);

            setFiles(event.newState);
        };

        const onProgress = (event: UploadOnProgressEvent) => {
            console.log("onProgress: ", event.affectedFiles);

            setFiles(event.newState);
        };

        const onStatusChange = (event: UploadOnStatusChangeEvent) => {
            console.log("onStatusChange: ", event.affectedFiles);

            setFiles(event.newState);
        };

        const getRequestData = () => {
            setTimeZones(raw_timezones);
            d4ur.getEventRequest(event_request_id).then((ret) => {
                const {
                    event,
                    // dealer,
                    charity,
                    planner,
                } = (ret as D4URFetchResult).result;

                const { requested_month, requested_date } = JSON.parse(event.eligibility_custom_data || '{}');

                const { centuryParty } = JSON.parse(event.event_custom_data || '{}');

                charity.address_2 = charity.address_2 || '';

                event.requested_month = requested_month;
                if (requested_date) {
                    event.requested_date = moment(requested_date).toDate();
                }
                if (event.start_date) {
                    event.start_date = moment(event.start_date).toDate();
                } else if (event.requested_date) {
                    event.start_date = event.requested_date;
                }
                if (event.start_time) {
                    event.start_time = moment(event.start_time, 'HH:mm:ss').toDate();
                }
                if (event.end_time) {
                    event.end_time = moment(event.end_time, 'HH:mm:ss').toDate();
                }
                event.event_type = centuryParty;

                if (!event.time_zone) {
                    const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    event.time_zone = browserTZ;
                }

                if (event.event_type) {
                    event.event_type = eventTypes.find(x => x.value === event.event_type);
                }

                event.zip_code = event.zip_code || '';
                event.location_phone = event.location_phone || '';

                setPlanner(planner as UserDetails);
                setThisEvent(event as D4UREvent);
                // setDealer(dealer as Dealer);
                setCharity(charity as Charity);
                setProcessing(false);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(getRequestData, []);
        const ref:any = React.useRef(null);
        const dateRef:any = React.useRef(null);
        const startTimeRef:any = React.useRef(null);
        const endTimeRef:any = React.useRef(null);

        const handleOnWheel = (event:any) => {
          event.stopPropagation();
        };
      
        React.useEffect(() => {
          ref?.current?.element.addEventListener('wheel', handleOnWheel);
          dateRef?.current?.element.addEventListener('wheel', handleOnWheel);
          startTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);
          endTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);
      
          return () => {
            ref?.current?.element.removeEventListener('wheel', handleOnWheel);
            dateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            startTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            endTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
          };
        });

        const onSubmit = React.useCallback(
            async (dataItem) => {
                setProcessing(true);
                console.log('dataItem!!!!!!!!', dataItem);

                // update status if needed
                if (dataItem.status === 'eligible' || dataItem.status === 'revise') {
                    dataItem.status = 'planning';
                }

                // format dates properly
                if (dataItem.start_date) {
                    dataItem.start_date = moment(dataItem.start_date).format('YYYY-MM-DD');
                }
                if (dataItem.start_time) {
                    dataItem.start_time = moment(dataItem.start_time).format('HH:mm:00');
                }
                if (dataItem.end_time) {
                    dataItem.end_time = moment(dataItem.end_time).format('HH:mm:00');
                }

                let customData = JSON.parse(thisEvent!.eligibility_custom_data || '{}');
                customData = {
                    ...customData,
                    requested_month: dataItem.requested_month,
                    requested_date: dataItem.requested_date,
                }

                dataItem.eligibility_custom_data = JSON.stringify(customData);

                let eventCustomData = JSON.parse(thisEvent!.event_custom_data || '{}');
                eventCustomData = {
                    ...eventCustomData,
                    centuryParty: dataItem.event_type?.value,
                }
                dataItem.event_custom_data = JSON.stringify(eventCustomData);
                dataItem.event_type = dataItem.event_type?.value;

                const [ selectedFile ] = files;
                const rawFile = selectedFile && selectedFile.getRawFile && selectedFile.getRawFile();
                if (rawFile) {
                    dataItem.event_plan_file = rawFile;
                }

                console.log('dataItem',dataItem);

                const call:any = await d4ur.saveEventPlan(dataItem);
                if (call.result.errors) {
                    console.log('[EventPlanningFormScreen]', 'Update Event Request Error');
                    setFormError(call.result.errors.join('\n'));
                    setProcessing(false);
                } else {
                    console.log('[EventPlanningFormScreen]', 'Event Request updated');
                    history.go(0);
                }
            },
            [d4ur, files, history, thisEvent]
        );

        const { eligibility_custom_data } = thisEvent || {};
        const { requested_date, requested_month, deny_message } = JSON.parse(eligibility_custom_data || '{}');

        const filterTimeZones = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
            return filterBy(raw_timezones, filter);
        };

        const filterTZ = (event: DropDownListFilterChangeEvent) => {
            setTimeZones(filterTimeZones(event.filter));
        };

        const formatRange = (start?:Date, end?:Date) => {
            let range = '';
            if (start) {
                range += moment(start).format('h:mm a');
            }
            if (start && end) {
                range += ' - ';
            }
            if (end) {
                range += moment(end).format('h:mm a');
            }

            return range;
        }

        const { centuryParty, file_id, file_name,  } = JSON.parse(thisEvent?.event_custom_data || '{}');

        const checkCompletePlan = () => {
            const errors:string[] = [];

            if (!thisEvent?.event_name) {
                errors.push('Event Name is required');
            }

            if (!centuryParty) {
                errors.push('Event Type is required');
            }

            if (!thisEvent?.funding_purpose) {
                errors.push('Funding Purpose is required');
            }

            if (!file_name) {
                errors.push('Event Plan file is required');
            }

            if (!thisEvent?.time_zone) {
                errors.push('time?.time_zone is required');
            }

            if (!thisEvent?.start_date) {
                errors.push('event date is required');
            }

            if (!thisEvent?.start_time) {
                errors.push('start_time is required');
            }

            if (!thisEvent?.end_time) {
                errors.push('end_time is required');
            }

            if (!thisEvent?.event_location_name) {
                errors.push('event_location_name is required');
            }

            if (!thisEvent?.address) {
                errors.push('Event address is required');
            }

            if (!thisEvent?.city) {
                errors.push('Event city is required');
            }

            if (!thisEvent?.state) {
                errors.push('Event state is required');
            }

            if (!thisEvent?.zip_code) {
                errors.push('Event zip is required');
            }

            if (!thisEvent?.location_contact) {
                errors.push('location_contact is required');
            }

            if (!thisEvent?.location_phone) {
                errors.push('location_phone is required');
            }

            if (!thisEvent?.location_email) {
                errors.push('location_email is required');
            }

            if (!thisEvent?.attendance) {
                errors.push('attendance is required');
            }

            return errors;
        };

        const doSubmitFinalPlan = React.useCallback(
            async () => {
                setProcessing(true);
                const call:any = await d4ur.submitRequest(thisEvent!.event_request_id!);
                if (call.result.errors) {
                    console.log('[EventPlanningFormScreen]', 'Update Event Request Error');
                    setFormError(call.result.errors.join('\n'));
                    setProcessing(false);
                } else {
                    console.log('[EventPlanningFormScreen]', 'Event Request updated');
                    history.push('/events');
                }
            }, [d4ur, history, thisEvent]
        );

        return (
            <div id="EventPlanning" className="event-planning-page">
                {
                    dialogDisplayed === 'submitFinalPlan' &&
                    <Dialog>
                        <h3>Submit Event Plan</h3>

                        <p>This will lock and submit your event plan to PHQ for final approval.</p>


                        <DialogActionsBar>
                            <Button
                                primary={true}
                                onClick={doSubmitFinalPlan}
                            >
                                {localizationService.toLanguageString('custom.submit_approval', 'submit_approval')}
                            </Button>
                            <Button onClick={() => setDialogDisplayed('none')}>
                                {localizationService.toLanguageString('custom.cancel', 'cancel')}
                            </Button>
                        </DialogActionsBar>
                    </Dialog>
                }
                {
                    dialogDisplayed === 'none' &&
                    <div className="card-container">
                        <h1 className="card-title">{localizationService.toLanguageString('custom.event_plan','event_plan')}</h1>

                        {
                            processing ? <div style={{textAlign:'center'}}><Loader type="infinite-spinner" size="large" /></div>
                            :
                            <div className="card-component">
                                <br/>
                                <h3>Plan Status: {thisEvent?.status}</h3>
                                {
                                    deny_message && <p><b>Message from PHQ:</b> {deny_message}</p>
                                }

                                <h3>Planner</h3>
                                <p>
                                    <Label className='info-label'>Name:</Label> {planner?.display_name}<br/>
                                    <Label className='info-label'>Email:</Label> <a href="mailto:{planner?.email}">{planner?.email}</a><br/>
                                    <Label className='info-label'>Phone:</Label> <a href="tel:{planner?.phone_number}">{planner?.phone_number}</a><br/>
                                </p>

                                <h3>Charity</h3>
                                <p>
                                    <Label className='info-label'>Name:</Label> {charity?.name}<br/>
                                </p>

                                {
                                    (formDisplayed !== 'eventRequest' && thisEvent?.event_request_id) &&
                                    <>
                                        <h3>
                                            {localizationService.toLanguageString('custom.event_plan', 'event_plan')} &nbsp;
                                            {
                                                ['eligible', 'planning', 'revise'].includes(thisEvent?.status || '') &&
                                                <>
                                                    <Button onClick={() => setFormDisplayed('eventRequest')}>Edit Event Plan</Button>
                                                    {
                                                        thisEvent.status !== 'revise' &&
                                                        <Button primary={true} onClick={() => setDialogDisplayed('submitFinalPlan')} disabled={checkCompletePlan().length > 0}>Submit Event Plan</Button>
                                                    }
                                                </>
                                            }
                                        </h3>
                                        <p>
                                            <Label className='info-label'>Name:</Label> {thisEvent?.event_name}<br/>
                                            <Label className='info-label'>Type:</Label> {eventTypes.find(x => x.value === centuryParty)?.text}<br/>
                                            {
                                                thisEvent.start_date ?
                                                <>
                                                    <Label className='info-label'>Event Date:</Label> {moment(thisEvent.start_date).format('dddd, MMMM Do')} {formatRange(thisEvent.start_time, thisEvent.end_time)}<br/>
                                                </> :
                                                <>
                                                    <Label className='info-label'>Requested Month:</Label> {requested_month}<br/>
                                                    {requested_date && <Label className='info-label'>Requested Date:</Label> }
                                                    {requested_date && `${moment(requested_date).format('dddd, MMMM Do')}`}
                                                    {requested_date && <br/>}
                                                </>
                                            }
                                            <Label className='info-label'>Projected Attendance:</Label> {thisEvent?.attendance}<br/>
                                            <Label className='info-label'>Event Plan File:</Label> {
                                                file_name && <a href={`https://lcp-event-plans.s3.us-west-2.amazonaws.com/2022/${file_id}/${encodeURIComponent(file_name)}`} target="_blank" rel="noreferrer">{file_name}</a>
                                            }<br/>
                                        </p>
                                    </>
                                }

                                { formDisplayed === 'eventRequest' &&
                                    <Form
                                        onSubmit={onSubmit}
                                        ignoreModified={true}
                                        initialValues={{
                                            ...formValues,
                                            ...thisEvent,
                                        }}
                                        render={(formRenderProps) => (
                                            <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                                <h3>Edit Event Plan</h3>
                                                { formError && <Error>{formError}</Error> }
                                                <Field
                                                    id={'event_name'}
                                                    name={'event_name'}
                                                    maxLength={25}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.event_name', 'event_name')}
                                                    validator={requiredValidator}
                                                    component={Input}
                                                />
                                                <Field
                                                    component={DropDownList}
                                                    id={'event_type'}
                                                    name={'event_type'}
                                                    label={localizationService.toLanguageString('custom.event_type', 'event_type')}
                                                    data={eventTypes}
                                                    dataItemKey="value"
                                                    textField="text"
                                                    // required={true}
                                                />
                                                <Field
                                                    id={'funding_purpose'}
                                                    name={'funding_purpose'}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.funding_purpose', 'funding_purpose')}
                                                    hint={localizationService.toLanguageString('custom.funding_purpose_hint', 'funding_purpose_hint')}
                                                    // validator={requiredValidator}
                                                    component={Input}
                                                />
                                                <Field
                                                    id={'event_plan_file'}
                                                    name={'event_plan_file'}
                                                    type={'file'}
                                                    multiple={false}
                                                    files={files}
                                                    onAdd={onAdd}
                                                    onRemove={onRemove}
                                                    onProgress={onProgress}
                                                    onStatusChange={onStatusChange}
                                                    onChange={() => formRenderProps.allowSubmit = true}
                                                    label={localizationService.toLanguageString('custom.event_plan_file', 'event_plan_file')}
                                                    hint={localizationService.toLanguageString('custom.event_plan_file_hint', 'event_plan_file_hint')}
                                                    // validator={requiredValidator}
                                                    component={Upload}
                                                />
                                                <Field
                                                    component={DropDownList}
                                                    id={'time_zone'}
                                                    name={'time_zone'}
                                                    label={localizationService.toLanguageString('custom.time_zone', 'time_zone')}
                                                    data={timeZones}
                                                    filterable={true}
                                                    onFilterChange={filterTZ}
                                                    // required={true}
                                                />
                                                <Field
                                                    id={'start_date'}
                                                    name={'start_date'}
                                                    childRef={dateRef}
                                                    label={localizationService.toLanguageString('custom.event_date', 'event_date')}
                                                    format="MM/dd/yyyy"
                                                    min={moment.max(moment('2022-03-01'), moment().add(60,'days')).toDate()}
                                                    max={moment('2022-11-06').toDate()}
                                                    component={DatePicker}
                                                    // required={true}
                                                />
                                                <Field
                                                    component={TimePicker}
                                                    childRef={startTimeRef}
                                                    id={'start_time'}
                                                    name={'start_time'}
                                                    label={localizationService.toLanguageString('custom.start_time', 'start_time')}
                                                    // required={true}
                                                />
                                                <Field
                                                    component={TimePicker}
                                                    childRef={endTimeRef}
                                                    id={'end_time'}
                                                    name={'end_time'}
                                                    label={localizationService.toLanguageString('custom.end_time', 'end_time')}
                                                    // required={true}
                                                />
                                                <Field
                                                    id={'event_location_name'}
                                                    name={'event_location_name'}
                                                    maxLength={255}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.event_location_name', 'event_location_name')}
                                                    // validator={requiredValidator}
                                                    hint={localizationService.toLanguageString('custom.event_location_hint', 'event_location_hint')}
                                                    component={Input}

                                                />
                                                <Field
                                                    id={'address'}
                                                    name={'address'}
                                                    maxLength={45}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.address', 'address')}
                                                    // validator={requiredValidator}
                                                    component={Input}
                                                />
                                                <Field
                                                    id={'city'}
                                                    name={'city'}
                                                    maxLength={45}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.city', 'city')}
                                                    // validator={requiredValidator}
                                                    component={Input}
                                                />
                                                <Field
                                                    component={DropDownList}
                                                    id={'state'}
                                                    name={'state'}
                                                    label={localizationService.toLanguageString('custom.state', 'state')}
                                                    data={states}
                                                    // validator={requiredValidator}
                                                    // required={true}
                                                />
                                                <Field
                                                    id={'zip_code'}
                                                    name={'zip_code'}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.zip', 'zip')}
                                                    required={true}
                                                    mask="00000"
                                                    component={MaskedTextBox}
                                                />
                                                <Field
                                                    id={'location_contact'}
                                                    name={'location_contact'}
                                                    maxLength={100}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.location_contact', 'location_contact')}
                                                    // validator={requiredValidator}
                                                    hint={localizationService.toLanguageString('custom.location_contact_hint', 'location_contact_hint')}

                                                    component={Input}
                                                />
                                                <Field
                                                    id={'location_phone'}
                                                    name={'location_phone'}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.location_phone', 'location_phone')}
                                                    required={true}
                                                    mask="000-000-0000"
                                                    placeholder={'e.g.: 000-000-0000'}
                                                    component={MaskedTextBox}
                                                />
                                                <Field
                                                    id={'location_email'}
                                                    name={'location_email'}
                                                    type={'email'}
                                                    placeholder={'e.g.: peter@gmail.com'}
                                                    label={localizationService.toLanguageString('custom.location_email', 'location_email')}
                                                    // validator={emailValidator}
                                                    // required={true}
                                                    component={Input}
                                                />
                                                <Field
                                                    id={'attendance'}
                                                    name={'attendance'}
                                                    childRef={ref}
                                                    // type={'input'}
                                                    label={localizationService.toLanguageString('custom.attendance', 'attendance')}
                                                    // validator={requiredValidator}
                                                    component={NumericTextBox}
                                                />
                                                <Field
                                                    id={'agree_terms'}
                                                    name={'agree_terms'}
                                                    label={`${localizationService.toLanguageString('custom.terms', 'terms')}`}
                                                    checkboxText={`${localizationService.toLanguageString('custom.agree_event_terms', 'agree_event_terms')}`}
                                                    hint={<a href="/terms-event" target="_blank">{localizationService.toLanguageString('custom.read_event_terms', 'read_event_terms')}</a>}
                                                    validator={requiredValidator}
                                                    required={true}
                                                    component={Checkbox}
                                                />
                                                <hr />
                                                <div className={'k-form-buttons'}>
                                                    <Button
                                                        primary={true}
                                                        type={'submit'}
                                                        disabled={!formRenderProps.allowSubmit}
                                                    >
                                                        {localizationService.toLanguageString('custom.save', 'save')}
                                                    </Button>

                                                    <Button onClick={() => setFormDisplayed('none')}>
                                                        {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                                    </Button>
                                                </div>
                                            </FormElement>
                                        )}
                                    />
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        );
}

export default EventPlanningFormScreen;
