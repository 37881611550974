import React, { useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { emailValidator } from '../validators';

import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import logo from "../assets/images/Ford_D4URS_Blue_Screen_RGB_v1.png";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import sideImage from "../assets/images/banner2_v4.jpg";
// import '../styles/_forgot_pass.scss';
import '../styles/_login.scss';

const ForgotPasswordScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const history = useHistory();
    const { language } = localizationService;
    const [passwordError, setPasswordError] = useState<string>();
    const [confirmMessage, setConfirmMessage] = useState<string>('');
    const { d4ur } = useServices();

    const onSubmit = React.useCallback(
        async (dataItem) => {
            const call: any = await d4ur.doForgotPassword(dataItem.contact_email);
            if (call.result.error) {
                console.log('[ForgotPasswordScreen]', 'Password Error');
                setPasswordError(call.result.error);
            }
            if (call.result.success) {
                console.log('[ForgotPasswordScreen]', 'email sent');
                setConfirmMessage(localizationService.toLanguageString('custom.confirmPasswordMessage', 'Please check your email'))
            }
        },
        [d4ur, localizationService]
    );

    return (
        <div id="ForgotPassword" className="forgot_pass">
            <div className='main'>
                <div className="left_content">
                {/* <div className={'logo_container'}> */}
                    <img src={logo} alt={'Ford Drive 4 UR School Logo'} className='logo' onClick={() => {
                                        history.push("/");
                                    }}/>
                {/* </div> */}
                <div className="form-container" style={{ maxWidth: 700 }}>
                        <h1 className="form_title">{localizationService.toLanguageString('custom.resetPassword', 'Reset Password')}</h1>
                        {/* <p className="card-text">{localizationService.toLanguageString('custom.resetText', 'Reset Text')}</p> */}
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...formValues,
                                contact_email: ''
                            }}
                            render={(formRenderProps) => (
                                <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                    {passwordError && <Error>{passwordError}</Error>}

                                    <Field
                                        id={'contact_email'}
                                        name={'contact_email'}
                                        type={'email'}
                                        autoComplete="username"
                                        placeholder={'e.g.: peter@gmail.com'}
                                        label={localizationService.toLanguageString('custom.dealerContactEmail', 'Email')}
                                        validator={emailValidator}
                                        required={true}
                                        component={Input}
                                    />

                                    <p>{confirmMessage && confirmMessage}</p>
                                    <div className={'log_in_button'}>
                                        <Button
                                            className={'log_in_btn'}
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}
                                        >
                                            {localizationService.toLanguageString('custom.submit', 'submit')}
                                        </Button>
                                    </div>
                                </FormElement>
                            )}
                        />
                        <p className="forgot-text">{localizationService.toLanguageString('custom.resetText', 'Reset Text')}</p>

                        <div className="reset_container">
                               <p className='sign_up_text'>Don't have an account?</p>
                               <button 
                                    className='sign_up_btn' 
                                    onClick={() => {
                                        history.push("/signup");
                                    }}>
                                    Sign Up
                                </button>
                            </div>
                        <div className="reset_container log_in">
                               <p className='sign_up_text'>Already have an account?</p>
                               <button 
                                    className='sign_up_btn' 
                                    onClick={() => {
                                        history.push("/login");
                                    }}>
                                    Log In
                                </button>
                            </div>
            </div>
                </div>
                <div className='right_content'>
                    <img src={sideImage} alt={'Ford vehicles parked in a school drop off lane'} className='right_img' />        
                </div>

            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', }}
                privacyContainerStyle={{ padding: '10px 0', width: "60%", marginLeft: '20%' }}
                showPrivacy={true}
                language={language}
                team={'ford'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact", target: '_blank' },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility", target: '_blank'},
                    { text: "PROGRAM RESOURCES ", url: "/resources", target: '_blank' },
                ]}
            />
        </div>
    );
}

export default ForgotPasswordScreen;
